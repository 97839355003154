import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import NewsComponent from '../components/NewsComponent'
import LogoGrid from '../components/LogoGrid'

export const NewsIndexPageTemplate = ({
    title,
    introduction,
    newsItems,
    featuredLogoGrid,
}) => {
    return (
        <div className={cx('max-w-1264 mx-auto mb-100 px-24 lg:px-32')}>
            <section className={cx('mt-60 lg:mt-120')}>
                <h1 className={cx('hd-jb text-purple-700')}>{title}</h1>
                {introduction && (
                    <p className={cx('lg:w-1/2 text-21 leading-snug mt-24')}>
                        {introduction}
                    </p>
                )}
            </section>
            {newsItems && (
                <section>
                    <NewsComponent newsItems={newsItems} className="mt-48" />
                </section>
            )}
            {featuredLogoGrid && (
                <LogoGrid logoGrid={featuredLogoGrid} className="mt-80" />
            )}
        </div>
    )
}

NewsIndexPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    introduction: PropTypes.string,
    newsItems: PropTypes.array,
    featuredLogoGrid: PropTypes.object,
}

const NewsIndexPage = ({ data }) => {
    const { frontmatter } = data.page

    const pressReleases = data.pressReleases.edges.map((pr) => {
        pr.node.frontmatter.url = pr.node.fields.slug
        return pr
    })
    const newsItems = data.newsItems.edges
        .concat(pressReleases)
        .sort((a, b) =>
            a.node.frontmatter.date < b.node.frontmatter.date ? 1 : -1
        )

    return (
        <Layout
            title={frontmatter.title}
            description={frontmatter.introduction}
        >
            <NewsIndexPageTemplate
                title={frontmatter.title}
                introduction={frontmatter.introduction}
                newsItems={newsItems}
                featuredLogoGrid={frontmatter.featuredLogoGrid}
            />
        </Layout>
    )
}

NewsIndexPage.propTypes = {
    data: PropTypes.shape({
        page: PropTypes.object,
        newsItems: PropTypes.object,
        pressReleases: PropTypes.object,
    }),
}

export default NewsIndexPage

export const pageQuery = graphql`
    query NewsIndexPageTemplate {
        page: markdownRemark(
            frontmatter: { templateKey: { eq: "news-index-page" } }
        ) {
            frontmatter {
                title
                introduction
                featuredLogoGrid {
                    heading
                    logoGridImages {
                        title
                        logo {
                            childImageSharp {
                                fluid(maxWidth: 300, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
        newsItems: allMarkdownRemark(
            sort: { order: DESC, fields: frontmatter___date }
            filter: { fileAbsolutePath: { regex: "/(collections/news)/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        date
                        source
                        title
                        url
                    }
                }
            }
        }
        pressReleases: allMarkdownRemark(
            sort: { order: DESC, fields: frontmatter___date }
            filter: {
                frontmatter: { templateKey: { eq: "press-release-page" } }
            }
        ) {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        templateKey
                        author
                        date
                        source
                        summary
                    }
                }
            }
        }
    }
`
