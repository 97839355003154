import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import ArrowRight from '../img/inline/arrow-right.svg'
import formatContentDate from './utils/formatContentDate'
import ResourceBlockHeading from './ResourceBlockHeading'

const NewsComponent = ({ newsHeading, newsItems, className }) => {
    return (
        <section id="news" className={cx(className)}>
            {newsHeading && (
                <ResourceBlockHeading
                    heading={newsHeading}
                    viewAllText="News"
                    viewAllSlug="/news"
                />
            )}

            {newsItems && (
                <ul className={cx('border-t border-gray-100')}>
                    {newsItems.map((item, i) => {
                        const {
                            title,
                            source,
                            date,
                            url,
                            templateKey,
                        } = item.node.frontmatter
                        const contentDate = formatContentDate(date)

                        return (
                            <li key={`${title}-${i}`}>
                                <a
                                    href={url}
                                    target={
                                        templateKey === 'press-release-page'
                                            ? ''
                                            : '_blank'
                                    }
                                    rel="noopener noreferrer"
                                    className={cx(
                                        'py-30 border-b border-gray-100 flex justify-between items-center group'
                                    )}
                                >
                                    <div>
                                        {title && (
                                            <h3
                                                className={cx(
                                                    'text-21 leading-normal text-blue-500 duration-200',
                                                    'group-hover:text-blue-700 group-focus:text-blue-700'
                                                )}
                                            >
                                                {title}
                                            </h3>
                                        )}
                                        {source && (
                                            <p
                                                className={cx(
                                                    'text-14 font-semibold leading-snug mt-12'
                                                )}
                                            >
                                                <span>{source}</span>
                                                {date && (
                                                    <time dateTime={date}>
                                                        , {contentDate}
                                                    </time>
                                                )}
                                            </p>
                                        )}
                                    </div>

                                    <div
                                        className={cx(
                                            'inline-block bg-blue-500 rect-nav-button rounded-full',
                                            'flex-shrink-0 flex items-center justify-center ml-36',
                                            'duration-200 group-hover:bg-blue-700 group-focus:bg-blue-700'
                                        )}
                                    >
                                        <ArrowRight
                                            className={cx(
                                                'text-white transform duration-200 group-hover:translate-x-4 group-focus:translate-x-4'
                                            )}
                                        />
                                    </div>
                                </a>
                            </li>
                        )
                    })}
                </ul>
            )}
        </section>
    )
}

NewsComponent.propTypes = {
    newsHeading: PropTypes.string,
    newsItems: PropTypes.array,
    className: PropTypes.string,
}

export default NewsComponent
